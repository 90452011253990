<template>
  <v-container>
    <v-card class="content-card-padding">
      <content-template
        :show-welcome-admin="true"
        :admins="companyAdmins"
        :items="contentArray"
        :default-array="defaultContent"
        :custom-array="customContent"
        :enabled-languages="company.enabledLanguages"
        :show-default-custom-options="showDefaultCustomOptions"
        @on-save-content="saveContent"
        @on-update-content="updateCustomContent"
        @on-update-contents="updateCustomContents"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';
import { ContentService, ContentTemplate, ValidateMixin } from '@kickbox/common-admin';
import userService from '../../services/userService';

export default {
  components: {
    ContentTemplate,
  },
  mixins: [ValidateMixin],
  data() {
    return {
      showDefaultCustomOptions: true,
      companyAdmins: [],
    };
  },
  computed: {
    ...mapGetters([
      'defaultContent',
      'customContent',
      'company',
    ]),
    itemsArray() {
      const items = Object.entries(DEFAULT_CONTENT.PAGES)
        .filter((page) => {
          const features = page[1].features || [];
          return !features.length || features.every((feature) => this.company.features[feature]);
        })
        .reduce((acc, page) => {
          [, acc[page[0]]] = page;
          return acc;
        }, {});
      return items;
    },
    contentArray() {
      return Object.values(this.itemsArray);
    },
    contentKeys() {
      return Object.values(this.contentArray).map((content) => content.key);
    }
  },
  async created() {
    this.companyAdmins = await userService.getAdminUsersWithType(ADMIN_ROLES.COMPANY_ADMINS);
    ContentService.getDefaultContent(this.contentKeys)
      .then((content) => {
        const updatedContent = content.map((contentItem) => ({ ...contentItem, isSaved: true }));
        this.$store.commit('setDefaultContent', updatedContent);
      });
    ContentService.getCustomContent(this.contentKeys, this.company.parseObject)
      .then((content) => {
        const updatedContent = content.map((contentItem) => ({ ...contentItem, isSaved: true }));
        this.$store.commit('setCustomContent', updatedContent);
      });
  },
  methods: {
    ...mapMutations([
      'addCustomContent',
      'deleteCustomContent',
      'updateCustomContent',
      'updateCustomContents',
    ]),
    saveContent(contentToUpdate, contentArray) {
      contentToUpdate.forEach(async (content) => {
        const result = await ContentService.manageContent(content, contentArray);
        this.updateCustomContent(result.content);
      });
      return this.$store.dispatch('showSnackBar', { text: 'The content has been updated.' });
    }
  }
};
</script>
